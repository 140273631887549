import { Month } from "../types/models/Month"

export default {
  months: [
    { label: 'JAN', value: '01' },
    { label: 'FEB', value: '02' },
    { label: 'MAR', value: '03' },
    { label: 'APR', value: '04' },
    { label: 'MAY', value: '05' },
    { label: 'JUN', value: '06' },
    { label: 'JUL', value: '07' },
    { label: 'AUG', value: '08' },
    { label: 'SEP', value: '09' },
    { label: 'OCT', value: '10' },
    { label: 'NOV', value: '11' },
    { label: 'DEC', value: '12' }
  ] as Array<Month>,
  years: [
    '2016',
    '2017',
    '2018',
    '2019',
    '2020',
    '2021',
    '2022',
    '2023',
      '2024',
      '2025',
  ]
}